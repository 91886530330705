import React from "react";
import PropTypes from "prop-types";

const ArrowRight = ({ className, color }) => (
  <svg className={className} viewBox="0 0 35 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.0093 11.4703C34.3211 11.201 34.5004 10.8093 34.5004 10.3973C34.5004 9.98524 34.3211 9.59357 34.0093 9.32425L23.612 0.344802C23.0194 -0.167005 22.1241 -0.101494 21.6123 0.491124C21.1005 1.08374 21.166 1.97905 21.7586 2.49086L29.2733 8.98079L1.41781 8.98079C0.634776 8.98079 0 9.61556 0 10.3986C0 11.1816 0.634775 11.8164 1.41781 11.8164L29.2702 11.8164L21.7586 18.3037C21.166 18.8155 21.1005 19.7108 21.6123 20.3034C22.1241 20.8961 23.0194 20.9616 23.612 20.4498L34.0093 11.4703Z"
      fill="#FF4024"
    />
  </svg>
);

ArrowRight.defaultProps = {
  className: ``,
  color: `#FA412F`
};

ArrowRight.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default ArrowRight;
