/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import Carousel from "~components/Carousel";
import CursorHoverable from "~components/CursorHoverable";
import CursorInverse from "~components/CursorInverse";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import Parallaxer from "~components/Parallaxer";
import PortraitCarousel from "~components/PortraitCarousel";
import RelatedProjects from "~components/RelatedProjects";
import ScreenHeight from "~components/ScreenHeight";
import SEO from "~components/SEO";
import Video from "~components/Video";
import { getRandomIntByRange, parseProjects } from "~utils/helpers";

const CaseStudyPage = ({ data, location }) => {
  const { setHeaderText } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const [relatedArchive, setRelatedArchive] = useState(null);
  const [relatedProject, setRelatedProject] = useState(null);
  const [introExpanded, setIntroExpanded] = useState(false);

  let caseStudyGlobals;

  if (data?.allDataYaml?.edges?.[0]) {
    data.allDataYaml.edges.forEach(({ node }) => {
      if (caseStudyGlobals) {
        return;
      }

      if (node?.buttonLabel && node?.footnote) {
        caseStudyGlobals = node;
      }
    });
  }

  //

  const { fields, frontmatter } = data.markdownRemark;
  const isArchive = !fields.slug.includes(`case-studies/`);

  //

  const { archives, projects } = parseProjects(data);

  if (isArchive) {
    if (!relatedProject) {
      setRelatedProject(projects[getRandomIntByRange(0, projects.length - 1)]);
    }

    if (!relatedArchive) {
      archives.forEach((archive, archiveIndex) => {
        if (relatedArchive) {
          return;
        }

        if (archive.fields.slug === fields.slug) {
          setRelatedArchive(
            archives[
              archiveIndex + 1 <= archives.length - 1 ? archiveIndex + 1 : 0
            ]
          );
        }
      });
    }
  } else {
    if (!relatedArchive) {
      setRelatedArchive(archives[getRandomIntByRange(0, archives.length - 1)]);
    }

    if (!relatedProject) {
      projects.forEach((project, projectIndex) => {
        if (relatedProject) {
          return;
        }

        if (project.fields.slug === fields.slug) {
          setRelatedProject(
            projects[
              projectIndex + 1 <= projects.length - 1 ? projectIndex + 1 : 0
            ]
          );
        }
      });
    }
  }

  //

  const toggleIntro = () => {
    setIntroExpanded(!introExpanded);
  };

  //

  useEffect(() => {
    setHeaderText(frontmatter.client);
  }, []);

  //

  const getMediaJSX = media => {
    let mediaJSX;

    if (media.videoURL && media.videoURL !== ``) {
      mediaJSX = (
        <Video
          className="w-full relative block"
          src={media.videoURL}
          controls={media.videoControls || false}
        />
      );
    } else if (media?.image?.childImageSharp?.fluid) {
      mediaJSX = (
        <Img
          className="w-full relative block"
          fluid={media.image.childImageSharp.fluid}
          alt={media.imageAlt}
        />
      );
    } else if (media?.image?.publicURL) {
      mediaJSX = (
        <img
          className="w-full relative block"
          src={media.image.publicURL}
          alt={media.imageAlt}
        />
      );
    }

    return mediaJSX;
  };

  const getCaseStudyJSX = sectionData => {
    const sectionJSX = [];

    if (sectionData?.[0]) {
      sectionData.forEach((section, sectionIndex) => {
        const sectionKey = `section-${sectionIndex}`;

        const background = section.backgroundColor || `#ffffff`;

        switch (section.type) {
          case `intro`: {
            //
            // topmost text module

            const innerJSX = (
              <section
                key={sectionKey}
                className={`w-full relative z-10 ${
                  isArchive ? `pt-v14 xs:pt-v30 pb-v8` : `pt-v6 xs:pt-v10 pb-v5`
                }`}
                style={{
                  background
                }}
              >
                <div className="grid">
                  <div
                    className={`case-study-page__intro ${
                      introExpanded ? `expanded` : ``
                    } grid-end-15 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:order-1 relative xs:mt-v10 overflow-hidden whitespace-pre-wrap f4`}
                  >
                    <CursorHoverable>
                      <div
                        role="presentation"
                        className="case-study-page__intro__bg transition-background w-full h-full absolute right-0 bottom-0 left-0 z-20 flex items-end justify-start"
                        onClick={toggleIntro}
                      >
                        <span className="block b1 text-left">
                          {introExpanded ? `Read less -` : `Read more +`}
                        </span>
                      </div>
                    </CursorHoverable>

                    <h2 className="f4 whitespace-pre-wrap">
                      {section.content}
                    </h2>
                  </div>

                  <div className="grid-end-3 sm:grid-end-12 grid-start-18 sm:grid-start-1 xs:order-0 xs:flex xs:flex-wrap">
                    {section?.attributesLeft?.[0] &&
                      section.attributesLeft.map(
                        ({ attributeKey, attributes }) => {
                          return (
                            <div
                              key={attributeKey}
                              className="w-full relative xs:mb-v1"
                            >
                              <h3 className="case-study-page__attribute-key caption">
                                {attributeKey}
                              </h3>

                              <ul className="mb-v1">
                                {attributes.map((attribute, attributeIndex) => {
                                  const innerKey = `${attributeKey}-${attributeIndex}`;

                                  return (
                                    <li key={innerKey} className="b3">
                                      {attribute}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div className="grid-end-3 sm:grid-end-12 xs:flex xs:flex-wrap">
                    {section?.attributesRight?.[0] &&
                      section.attributesRight.map(
                        ({ attributeKey, attributes }) => {
                          return (
                            <div key={attributeKey} className="xs:mb-v1">
                              <h3 className="case-study-page__attribute-key caption">
                                {attributeKey}
                              </h3>

                              <ul className="mb-v1 ">
                                {attributes.map((attribute, attributeIndex) => {
                                  const liKey = `${attributeKey}-${attributeIndex}`;

                                  return (
                                    <li key={liKey} className="b3">
                                      {attribute}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </section>
            );

            sectionJSX.push(
              <>
                {(!isArchive && (
                  <AppearOnScroll atTop>{innerJSX}</AppearOnScroll>
                )) ||
                  innerJSX}
              </>
            );

            break;
          }

          case `text`: {
            //
            // text module 1: simple text

            let contentFontSize;

            switch (section.size.toLowerCase()) {
              case `small`:
                contentFontSize = `f4`;
                break;

              case `large`:
              default:
                contentFontSize = `f3`;
                break;
            }

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block pt-v9 xs:pt-v20 pb-v11 xs:pb-v20"
                style={{
                  background,
                  color: section.fontColor
                }}
              >
                <div className="grid">
                  <article className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1">
                    {section?.heading && section.heading !== `` && (
                      <h2 className="whitespace-pre-wrap mb-v3 xs:mb-v8 f5">
                        {section.heading}
                      </h2>
                    )}

                    {section?.content && section.content !== `` && (
                      <p className={`whitespace-pre-wrap ${contentFontSize}`}>
                        {section.content}
                      </p>
                    )}

                    {section?.subheading && section.subheading !== `` && (
                      <h3 className="mt-v4 xs:mt-v10 whitespace-pre-wrap b1">
                        {section.subheading}
                      </h3>
                    )}
                  </article>
                </div>
              </section>
            );

            break;
          }

          case `2UpText`:
            //
            // text module 2: 2 side-by-side text columns; heading left, content right

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block pt-v8 xs:pt-v20 pb-v8 xs:pb-v20"
                style={{
                  background,
                  color: section.fontColor
                }}
              >
                <article className="grid">
                  <h3 className="grid-end-6 sm:grid-end-24 grid-start-2 sm:grid-start-1 whitespace-pre-wrap f5">
                    {section.heading}
                  </h3>

                  <p className="grid-end-15 sm:grid-end-24 grid-start-9 sm:grid-start-1 xs:mt-v8 whitespace-pre-wrap b1">
                    {section.content}
                  </p>
                </article>
              </section>
            );

            break;

          case `media`: {
            //
            // media module 1: simple / centered to full-width or grid

            const mediaJSX = getMediaJSX(section);

            let mediaContainerJSX;

            if (!section?.size || section.size === `full`) {
              mediaContainerJSX = mediaJSX;
            } else {
              mediaContainerJSX = (
                <div className="grid pt-v5 xs:pt-v7 pb-v5 xs:pb-v7">
                  <figure className="grid-end-24">{mediaJSX}</figure>
                </div>
              );
            }

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block"
                style={{
                  background
                }}
              >
                {mediaContainerJSX}
              </section>
            );

            break;
          }

          case `carousel`: {
            //
            // media module 2: full-width carousel

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block"
                style={{
                  background
                }}
              >
                {section?.media?.[0] && (
                  <Carousel
                    keyPrefix={sectionKey}
                    items={section.media.map(media => {
                      return getMediaJSX(media);
                    })}
                  />
                )}
              </section>
            );

            break;
          }

          case `portraitCarousel`: {
            //
            // media module 3: carousel of portrait images

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block"
                style={{
                  background
                }}
              >
                {section?.media?.[0] && (
                  <PortraitCarousel
                    keyPrefix={sectionKey}
                    items={section.media.map(media => {
                      return getMediaJSX(media);
                    })}
                  />
                )}
              </section>
            );

            break;
          }

          case `mixMedia`: {
            //
            // media module 4: left / right media, one big, one small

            // const mediaJSX = getMediaJSX(section);

            let alignClass = `items-start`;
            let classNameLeft = `grid-end-14 sm:grid-end-24 grid-start-1 sm:grid-start-1`;
            let classNameRight = `grid-end-7 sm:grid-end-12 grid-start-18 sm:grid-start-13 xs:mt-8`;

            if (section?.size !== `leftLarge`) {
              alignClass = `items-end`;
              classNameLeft = `grid-end-7 sm:grid-end-12 grid-start-1`;
              classNameRight = `grid-end-14 sm:grid-end-24 grid-start-12 sm:grid-start-1 xs:mt-8`;
            }

            //

            let mediaLeftJSX;

            if (section?.videoLeftURL && section.videoLeftURL !== ``) {
              mediaLeftJSX = (
                <Video
                  className="w-full h-full relative block object-cover"
                  src={section.videoLeftURL}
                  controls={section.videoLeftControls || false}
                />
              );
            } else if (section?.imageLeft?.childImageSharp?.fluid) {
              mediaLeftJSX = (
                <Img
                  className="w-full h-full relative block object-cover"
                  fluid={section.imageLeft.childImageSharp.fluid}
                  alt={section.imageLeftAlt}
                />
              );
            } else if (section?.imageLeft?.publicURL) {
              mediaLeftJSX = (
                <img
                  className="w-full h-full relative block object-cover"
                  src={section.imageLeft.publicURL}
                  alt={section.imageLeftAlt}
                />
              );
            }

            let mediaRightJSX;

            if (section?.videoRightURL && section.videoRightURL !== ``) {
              mediaRightJSX = (
                <Video
                  className="w-full h-full relative block object-cover"
                  src={section.videoRightURL}
                  controls={section.videoRightControls || false}
                />
              );
            } else if (section?.imageRight?.childImageSharp?.fluid) {
              mediaRightJSX = (
                <Img
                  className="w-full h-full relative block object-cover"
                  fluid={section.imageRight.childImageSharp.fluid}
                  alt={section.imageRightAlt}
                />
              );
            } else if (section?.imageRight?.publicURL) {
              mediaRightJSX = (
                <img
                  className="w-full h-full relative block object-cover"
                  src={section.imageRight.publicURL}
                  alt={section.imageRightAlt}
                />
              );
            }

            //

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block pt-24 pb-24"
                style={{
                  background
                }}
              >
                <div className={`grid ${alignClass}`}>
                  <div className={classNameLeft}>
                    {mediaLeftJSX && mediaLeftJSX}
                  </div>

                  <div className={classNameRight}>
                    {mediaRightJSX && mediaRightJSX}
                  </div>
                </div>
              </section>
            );

            break;
          }

          case `quote`: {
            //
            // media module 5: large quote next to an image module

            const gridAlignContent = `grid-end-13 sm:grid-end-24 grid-start-2 sm:grid-start-1`;
            const gridAlignMedia = `grid-end-8 sm:grid-end-24 grid-start-17 sm:grid-start-1`;

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block"
                style={{
                  background,
                  color: section.fontColor
                }}
              >
                <article className="grid items-center pt-v5 xs:pt-v12 pb-v8 xs:pb-v20">
                  <div className={gridAlignContent}>
                    <h3 className="xs:mt-v12 mb-v2 xs:mb-v16 whitespace-pre-wrap f2">
                      {section.content}
                    </h3>

                    <p className="xs:mb-v12 whitespace-pre-wrap b1">
                      {section.author}
                    </p>
                  </div>

                  <div className={gridAlignMedia}>
                    <Img
                      className="w-full relative block"
                      fluid={section.image.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </article>
              </section>
            );

            break;
          }

          case `mediaArticle`: {
            //
            // media module 6: squared media with accompanying text

            const mediaJSX = getMediaJSX(section);

            let gridAlignMedia = `grid-end-14 sm:grid-end-24 grid-start-2 sm:grid-start-1`;
            let gridAlignContent = `grid-end-7 sm:grid-end-24 grid-start-18 sm:grid-start-1`;

            if (section.contentAlign && section.contentAlign === `left`) {
              gridAlignMedia = `grid-end-14 sm:grid-end-24 grid-start-11 sm:grid-start-1 order-1`;
              gridAlignContent = `grid-end-7 sm:grid-end-24 grid-start-2 sm:grid-start-1 order-0`;
            }

            sectionJSX.push(
              <section
                key={sectionKey}
                className="w-full relative block"
                style={{
                  background
                }}
              >
                <article className="grid items-end pt-v5 xs:pt-v12 pb-v8 xs:pb-v20">
                  <div className={gridAlignMedia}>{mediaJSX}</div>

                  <div className={gridAlignContent}>
                    <h3 className="xs:mt-v12 mb-v3 xs:mb-v12 whitespace-pre-wrap f5">
                      {section.heading}
                    </h3>

                    <p className="whitespace-pre-wrap b1">{section.content}</p>
                  </div>
                </article>
              </section>
            );

            break;
          }

          default:
            break;
        }
      });
    }

    return sectionJSX;
  };

  //

  let bannerMediaJSX;

  if (!isArchive) {
    if (device && device === `desktop`) {
      if (
        frontmatter?.featuredVideoURL &&
        frontmatter.featuredVideoURL !== ``
      ) {
        bannerMediaJSX = (
          <Video
            className="w-full h-full relative block object-cover"
            src={frontmatter.featuredVideoURL}
            controls={frontmatter.featuredVideoControls || false}
          />
        );
      } else if (frontmatter?.featuredImage?.childImageSharp?.fluid) {
        bannerMediaJSX = (
          <Img
            className="w-full h-full relative block object-cover"
            fluid={frontmatter.featuredImage.childImageSharp.fluid}
            alt={frontmatter.featuredImageAlt}
          />
        );
      } else if (frontmatter?.featuredImage?.publicURL) {
        bannerMediaJSX = (
          <img
            className="w-full h-full relative block object-cover"
            src={frontmatter.featuredImage.publicURL}
            alt={frontmatter.featuredImageAlt}
          />
        );
      }
    } else if (
      frontmatter?.featuredVideoURLXS &&
      frontmatter.featuredVideoURLXS !== ``
    ) {
      bannerMediaJSX = (
        <Video
          className="w-full h-full relative block object-cover"
          src={frontmatter.featuredVideoURLXS}
          controls={frontmatter.featuredVideoControlsXS || false}
        />
      );
    } else if (frontmatter?.featuredImageXS?.childImageSharp?.fluid) {
      bannerMediaJSX = (
        <Img
          className="w-full h-full relative block object-cover"
          fluid={frontmatter.featuredImageXS.childImageSharp.fluid}
          alt={frontmatter.featuredImageAlt}
        />
      );
    } else if (frontmatter?.featuredImageXS?.publicURL) {
      bannerMediaJSX = (
        <img
          className="w-full h-full relative block object-cover"
          src={frontmatter.featuredImageXS.publicURL}
          alt={frontmatter.featuredImageAlt}
        />
      );
    }
  }

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="case-study-page w-full relative">
        {device && device === `desktop` && (
          <>
            {!isArchive && (
              <div className="desktop-only w-full sixteen-by-nine overflow-hidden">
                <Parallaxer
                  atTop
                  reverse
                  severe
                  className="h-full relative block object-over"
                >
                  <section className="animation-appear-down animation-delay-1 w-full h-full relative">
                    {bannerMediaJSX && bannerMediaJSX}
                  </section>
                </Parallaxer>
              </div>
            )}

            {getCaseStudyJSX(frontmatter.sections)}
          </>
        )}

        {device && device !== `desktop` && (
          <>
            {!isArchive && (
              <ScreenHeight className="touch-only overflow-hidden">
                <Parallaxer
                  atTop
                  reverse
                  severe
                  className="h-full relative block object-over"
                >
                  <section className="w-full h-full relative">
                    {bannerMediaJSX && bannerMediaJSX}
                  </section>
                </Parallaxer>
              </ScreenHeight>
            )}

            {getCaseStudyJSX(frontmatter.mobileSections)}
          </>
        )}

        <RelatedProjects
          archive={relatedArchive}
          mode={isArchive ? `archive` : `project`}
          project={relatedProject}
        />

        {caseStudyGlobals?.footnote && (
          <CursorInverse>
            <section className="w-full relative overflow-hidden bg-black text-white">
              <div className="grid">
                <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 pt-v10 xs:pt-v20 pb-v10 xs:pb-v16">
                  <h3 className="f3">{caseStudyGlobals.footnote}</h3>

                  <a
                    href={`mailto:${caseStudyGlobals.buttonTarget}`}
                    className={`mt-v3 xs:mt-v12 block ${
                      device === `desktop` ? `f5` : `b1`
                    }`}
                  >
                    + {caseStudyGlobals.buttonLabel}
                  </a>
                </div>
              </div>
            </section>
          </CursorInverse>
        )}
      </Layout>

      <Footer />
    </>
  );
};

export default CaseStudyPage;

export const query = graphql`
  query CaseStudyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        client
        priority
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        featuredImageXS {
          childImageSharp {
            fluid(maxWidth: 768, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        featuredImageAlt
        featuredVideoURL
        featuredVideoURLXS
        featuredVideoControls
        sections {
          type
          heading
          content
          contentAlign
          subheading
          author
          backgroundColor
          fontColor
          size
          attributesLeft {
            attributeKey
            attributes
          }
          attributesRight {
            attributeKey
            attributes
          }
          media {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            imageAlt
            videoURL
            videoControls
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageLeft {
            childImageSharp {
              fluid(maxWidth: 720, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageLeftAlt
          videoLeftURL
          videoLeftControls
          imageRight {
            childImageSharp {
              fluid(maxWidth: 720, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageRightAlt
          videoRightURL
          videoRightControls
          videoURL
          videoControls
        }
        mobileSections {
          type
          heading
          content
          subheading
          author
          backgroundColor
          fontColor
          size
          attributesLeft {
            attributeKey
            attributes
          }
          attributesRight {
            attributeKey
            attributes
          }
          media {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            imageAlt
            videoURL
            videoControls
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageLeft {
            childImageSharp {
              fluid(maxWidth: 720, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageLeftAlt
          videoLeftURL
          videoLeftControls
          imageRight {
            childImageSharp {
              fluid(maxWidth: 720, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageRightAlt
          videoRightURL
          videoRightControls
          videoURL
          videoControls
        }
        seoDescription
        seoKeywords
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            priority
            date
            backgroundColor
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 768, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            featuredImageAlt
            featuredVideoURL
            featuredVideoControls
          }
        }
      }
    }

    allDataYaml {
      edges {
        node {
          footnote
          buttonLabel
          buttonTarget
        }
      }
    }
  }
`;
