import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import ArrowRight from "~components/svg/ArrowRight";
import CursorHoverable from "~components/CursorHoverable";

const Carousel = ({ className, items, keyPrefix }) => {
  const { device, windowWidth } = useContext(DocumentContext);

  const glideRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [glide, setGlide] = useState(null);

  //

  const moveCarousel = () => {
    if (!glide) {
      return;
    }

    glide.go(`>`);
  };

  useEffect(() => {
    if (!glideRef?.current || !windowWidth || glide) {
      return;
    }

    const glideCarousel = new Glide(`#${keyPrefix}-glide`, {
      breakpoints: {
        768: {
          autoplay: false,
          gap: 0,
          hoverpause: false,
          peek: 0,
          perView: 1
        }
      },
      autoplay: 3000,
      gap: 0,
      hoverpause: true,
      perView: 1,
      type: `carousel`
    });

    glideCarousel.on(`run`, () => {
      setActiveIndex(glideCarousel.index);
    });

    glideCarousel.mount();

    setGlide(glideCarousel);
  }, [glideRef, windowWidth]);

  //

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`carousel ${className || `w-full relative`} overflow-x-hidden`}
    >
      <div id={`${keyPrefix}-glide`} ref={glideRef} className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {items.map((itemJSX, itemIndex) => {
              const key = `${keyPrefix}-item-${itemIndex}`;

              return (
                <li key={key} className="glide__slide relative">
                  {itemJSX}
                </li>
              );
            })}
          </ul>
        </div>

        {items?.[1] && (
          <div className="w-1/2 h-full absolute top-0 right-0 bottom-0 z-10 flex items-center justify-end pointer-events-none">
            <CursorHoverable>
              <button
                type="button"
                className="relative block pointer-events-auto"
                onClick={() => moveCarousel()}
              >
                <ArrowRight className="w-10 mr-v6" />
              </button>
            </CursorHoverable>
          </div>
        )}

        <div
          className="glide__bullets touch-only w-full absolute bottom-0 right-0 left-0 flex items-center justify-center pb-v8"
          data-glide-el="controls[nav]"
        >
          {items.map((_, bulletIndex) => {
            const buttonKey = `${keyPrefix}-bullet-${bulletIndex}`;

            return (
              <button
                key={buttonKey}
                type="button"
                className={`glide__bullet ${
                  activeIndex === bulletIndex ? `` : `opacity-25`
                } relative block rounded-full bg-white`}
                data-glide-dir={`=${bulletIndex}`}
              ></button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

Carousel.defaultProps = {
  className: ``,
  keyPrefix: `glide-carousel`
};

Carousel.propTypes = {
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string
};

export default Carousel;
