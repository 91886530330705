import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import ArrowRight from "~components/svg/ArrowRight";

const PortraitCarousel = ({ className, items, keyPrefix }) => {
  const { device, windowWidth } = useContext(DocumentContext);

  const glideRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [glide, setGlide] = useState(null);

  //

  const moveCarousel = () => {
    if (!glide) {
      return;
    }

    glide.go(`>`);
  };

  useEffect(() => {
    if (!glideRef?.current || !windowWidth || glide) {
      return;
    }

    const glideCarousel = new Glide(`#${keyPrefix}-glide`, {
      breakpoints: {
        768: {
          autoplay: false,
          gap: parseInt(windowWidth * 0.07),
          hoverpause: false,
          peek: parseInt(windowWidth * 0.15),
          perView: 1
        }
      },
      autoplay: 3000,
      gap: parseInt(windowWidth * 0.04),
      hoverpause: true,
      peek: parseInt(windowWidth * 0.06),
      perView: 3.1,
      type: `carousel`
    });

    glideCarousel.on(`run`, () => {
      setActiveIndex(glideCarousel.index);
    });

    glideCarousel.mount();

    setGlide(glideCarousel);
  }, [glideRef, windowWidth]);

  //

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`portrait-carousel ${className ||
        `w-full relative`} xs:pt-v8 overflow-x-hidden`}
    >
      <div
        id={`${keyPrefix}-glide`}
        ref={glideRef}
        className="glide mt-v6 mb-v12"
      >
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {items.map((itemJSX, itemIndex) => {
              const key = `${keyPrefix}-item-${itemIndex}`;

              return (
                <li key={key} className="glide__slide relative">
                  {itemJSX}
                </li>
              );
            })}
          </ul>
        </div>

        {items?.[1] && (
          <CursorHoverable className="desktop-only absolute right-0 bottom-0 block mb-v8 mr-v6">
            <button
              type="button"
              className="relative block"
              onClick={() => moveCarousel()}
            >
              <ArrowRight className="w-12" />
            </button>
          </CursorHoverable>
        )}

        <div
          className="glide__bullets touch-only w-full relative flex items-center justify-center pt-v12"
          data-glide-el="controls[nav]"
        >
          {items.map((_, bulletIndex) => {
            const buttonKey = `${keyPrefix}-bullet-${bulletIndex}`;

            return (
              <button
                key={buttonKey}
                type="button"
                className={`glide__bullet ${
                  activeIndex === bulletIndex ? `` : `opacity-25`
                } relative block rounded-full bg-black`}
                data-glide-dir={`=${bulletIndex}`}
              ></button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

PortraitCarousel.defaultProps = {
  className: ``,
  keyPrefix: `glide-carousel`
};

PortraitCarousel.propTypes = {
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string
};

export default PortraitCarousel;
