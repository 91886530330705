/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import CursorHoverable from "~components/CursorHoverable";
import Video from "~components/Video";

const RelatedProjects = ({ archive, mode = `project`, project }) => {
  let archiveMediaJSX;

  if (
    archive?.frontmatter?.featuredVideoURL &&
    archive.frontmatter.featuredVideoURL !== ``
  ) {
    archiveMediaJSX = (
      <Video
        className="w-full h-full relative block object-cover"
        src={archive.frontmatter.featuredVideoURL}
        controls={archive.frontmatter.featuredVideoControls || false}
      />
    );
  } else if (archive?.frontmatter?.featuredImage?.childImageSharp?.fluid) {
    archiveMediaJSX = (
      <Img
        className="w-full h-full relative block object-cover"
        fluid={archive.frontmatter.featuredImage.childImageSharp.fluid}
        alt={archive.frontmatter.featuredImageAlt}
      />
    );
  } else if (archive?.frontmatter?.featuredImage?.publicURL) {
    archiveMediaJSX = (
      <img
        className="w-full h-full relative block object-cover"
        src={archive.frontmatter.featuredImage.publicURL}
        alt={archive.frontmatter.featuredImageAlt}
      />
    );
  }

  const archiveJSX = (
    <CursorHoverable>
      <Link to={archive.fields.slug}>
        <div className="related-projects__media square">
          {archiveMediaJSX && (
            <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0">
              {archiveMediaJSX}
            </figure>
          )}
        </div>

        <div className="related-projects__meta w-full relative mt-v1 flex items-start">
          <h3 className="w-3/4 relative text-left f5">
            {archive.frontmatter.title}
          </h3>
          <h4 className="w-1/4 underline b1 text-right">View Archive</h4>
        </div>
      </Link>
    </CursorHoverable>
  );

  //

  let projectMediaJSX;

  if (
    project?.frontmatter?.featuredVideoURL &&
    project.frontmatter.featuredVideoURL !== ``
  ) {
    projectMediaJSX = (
      <Video
        className="w-full h-full relative block object-cover"
        src={project.frontmatter.featuredVideoURL}
        controls={project.frontmatter.featuredVideoControls || false}
      />
    );
  } else if (project?.frontmatter?.featuredImage?.childImageSharp?.fluid) {
    projectMediaJSX = (
      <Img
        className="w-full h-full relative block object-cover"
        fluid={project.frontmatter.featuredImage.childImageSharp.fluid}
        alt={project.frontmatter.featuredImageAlt}
      />
    );
  } else if (project?.frontmatter?.featuredImage?.publicURL) {
    projectMediaJSX = (
      <img
        className="w-full h-full relative block object-cover"
        src={project.frontmatter.featuredImage.publicURL}
        alt={project.frontmatter.featuredImageAlt}
      />
    );
  }

  const projectJSX = (
    <CursorHoverable>
      <Link to={project.fields.slug}>
        <div className="related-projects__media square">
          {projectMediaJSX && (
            <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0">
              {projectMediaJSX}
            </figure>
          )}
        </div>

        <div className="related-projects__meta w-full relative mt-v1 flex items-start">
          <h3 className="w-3/4 relative text-left f5">
            {project.frontmatter.title}
          </h3>
          <h4 className="w-1/4 underline b1 text-right">View Project</h4>
        </div>
      </Link>
    </CursorHoverable>
  );

  //

  return (
    <section className="related-projects w-full relative block pt-v4 pb-v8 bg-white">
      <div className="grid">
        <article className="related-projects__item grid-end-10 sm:grid-end-24 grid-start-2 sm:grid-start-1">
          <h3 className="mb-v1 xs:mb-v3 f4">
            {mode === `project` ? `Archive` : `Recent Project`}
          </h3>

          {(mode === `project` && archiveJSX) || projectJSX}
        </article>

        <article className="related-projects__item grid-end-10 sm:grid-end-24 grid-start-14 sm:grid-start-1 text-right xs:text-left">
          <h3 className="mb-v1 xs:mb-v3 f4">
            Next {mode === `project` ? `Project` : `Archive`}
          </h3>

          {(mode === `project` && projectJSX) || archiveJSX}
        </article>
      </div>
    </section>
  );
};

export default RelatedProjects;
